<template>
  <div class="min-h-screen sela-container">
    <HomeSwiperMaterials
      :title="t('offers')"
      key-cache="offers"
      :view-more="{
        path: '/offers'
      }"
      :fetch-params="{ offersOnly: true }"
    />
    <HomeAdSecondarySwiper
      :ads="{ error: getErrors, items: getAnnouncements?.homeEndPageAdverts }"
    />
    <HomeSwiperMaterials
      key-cache="the_most_demanded"
      :title="t('the_most_demanded')"
      route="/agent/get-top-material-for-each-category"
    />
    <HomeOffersGroupSlider class="mb-5" :enable-cache="true" />

    <HomeSwiperMaterials
      key-cache="most_watched"
      :title="t('most_watched')"
      :view-more="{
        path: '/materials',
        query: { order_by_view_number: 1 }
      }"
      :fetch-params="{ order_by_view_number: 1 }"
    />
    <HomeAdAcrossMaterials
      v-if="getAnnouncements.homeCrossMaterialsAdverts?.length"
      :ads="getAnnouncements.homeCrossMaterialsAdverts[0]"
    />
    <HomeGridMaterials :initial-limit="50">
      <template #after-first-twenty-materials>
        <HomeSwiperMaterials
          key-cache="featured_materials"
          :title="t('featured_materials')"
          :view-more="{
            path: '/materials',
            query: { is_featured: true }
          }"
          :fetch-params="{ is_featured: true }"
        />
        <HomeAdAcrossMaterials
          v-if="
            getAnnouncements.homeCrossMaterialsAdverts?.length &&
              getAnnouncements.homeCrossMaterialsAdverts?.length > 1
          "
          :ads="getAnnouncements.homeCrossMaterialsAdverts[1]"
        />
      </template>
      <template #after-second-twenty-materials>
        <HomeSwiperMaterials
          :title="t('Recently_added')"
          key-cache="Recently_added"
          :view-more="{
            path: '/materials',
            query: { order_by_latest: 1 }
          }"
          :fetch-params="{ order_by_latest: 1 }"
        />
        <HomeAdAcrossMaterials
          v-if="
            getAnnouncements.homeCrossMaterialsAdverts?.length &&
              getAnnouncements.homeCrossMaterialsAdverts?.length > 2
          "
          :ads="getAnnouncements.homeCrossMaterialsAdverts[2]"
        />
      </template>
    </HomeGridMaterials>
  </div>
</template>

<script setup lang="ts">
const { url } = useDomainHost()
const { t } = useI18n()
const { getErrors, getAnnouncements } = useAnnouncementStore()
useServerSeoMeta(
  useOgMeta(url, t('home'), t('sila_description'), url + '/images/sela.webp')
)

useHead({
  title: t('home'),
  link: [useSeoCanonical(url + '/')],
  script: [useSeoBreadcrumb([useSeoBreadcrumbItem(1, t('home'), url)])]
})
</script>
